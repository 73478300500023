import { Button, Modal, notification, Progress } from 'antd'
import styles from './UploadCsvModal.module.css'
require('./UploadCsvModal.less')
import { DownloadOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Tasks } from '../../services/api/firebase'
import { useSelector } from 'react-redux'
import { FlatfileProvider } from '@flatfile/react'
import { Upload } from '../../services/api/upload'
import FlatFilePortal from '../flatfile-portal'

const UploadCsvModal = ({
	title,
	type,
	fields = [],
	firstStepPrompt,
	secondStepPrompt,
	visible,
	loading = false,
	onCancel,
	downloadTask,
	uploadTask,
	onComplete,
	importOnly = false // Flag indicates we do not proceed with uploading the data.
}) => {
	const { userProfile } = useSelector(state => state.authReducer)
	const [isDownloadingCsv, setIsDownloadingCsv] = useState(false)
	const [file, setFile] = useState()
	const [importedValues, setImportedValues] = useState([])
	const [isUploadingCsv, setIsUploadingCsv] = useState(false)
	const [logs, setLogs] = useState([])
	const [taskId, setTaskId] = useState()
	const [progress, setProgress] = useState(0)
	const [status, setStatus] = useState()
	const [isDownloadingErrorCsv, setIsDownloadingErrorCsv] = useState(false)

	useEffect(() => {
		if (taskId) {
			const unsubscribeTask = Tasks.listenToTask(
				userProfile.companyId,
				taskId,
				task => {
					const count = task.processedRows || 0
					const length = task.length || 1
					const progress = count / length * 100
					setProgress(progress)
					if (progress >= 100) {
						setStatus('completed')
						unsubscribeTask()
						if (task.logs) {
							setLogs(logs => [...logs, ...task.logs])
						}
					} else {
						if (task.loadState === 'FAILED') {
							setStatus('exception')
						}
						if (task.logs) {
							setLogs(logs => [...logs, ...task.logs])
						}
					}
				}
			)
			return () => {
				unsubscribeTask()
			}
		}
	}, [taskId])

	const downloadCsv = async () => {
		setIsDownloadingCsv(true)
		if (downloadTask) {
			await downloadTask()
		}
		setIsDownloadingCsv(false)
	}

	const onDownloadErrorRows = async () => {
		setIsDownloadingErrorCsv(true)
		await Upload.downloadTaskErrors(taskId)
		setIsDownloadingErrorCsv(false)
	}

	const onUploadFile = async () => {
		try {
			if (importOnly) {
				if (onComplete) {
					onComplete(importedValues)
				}
			} else {
				setIsUploadingCsv(true)
				setLogs([])
				if (uploadTask) {
					const response = await uploadTask(file)
					const { taskId, errorMessages } = response.data
					setLogs(errorMessages)
					if (errorMessages.length === 0) {
						setTaskId(taskId)
						setStatus('active')
					}
				}
			}
		} catch (e) {
			setLogs([])
			notification.error({
				message: 'File Upload Failed',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUploadingCsv(false)
		}
	}

	const onOk = () => {
		if (onComplete) {
			onComplete()
		}
		onCancel()
	}

	const parseResults = (results) => {
		const { fileName, validData } = results
		return new Promise(resolve => {
			const data = [].concat.apply([], validData)
			if (data.length > 0) {
				const headerRow = data[0]
				let header = Object.keys(headerRow)
				if (headerRow.$custom) {
					header = [...header, ...Object.keys(headerRow.$custom)].filter(key => key !== '$custom')
				}
				const csv = [
					header.join(','),
					...data.map(row => {
						if (row.$custom) {
							const custom = { ...row.$custom }
							delete row.$custom
							row = {
								...custom,
								...row
							}
						}
						return header
							.map(fieldName => {
								let value = row[fieldName]
								if (value === null || value === undefined) {
									value = ''
								} else if (typeof value !== 'string') {
									value = String(value)
								}
								if (value.includes(',') || value.includes('\n') || value.includes('\r') || value.includes('"')) {
									return `"${value.replace(/"/g, '""')}"`
								} else {
									return value
								}
							})
							.join(',')
					})
				].join('\r\n')
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
				const file = new File([blob], fileName, { type: 'text/csv' })
				resolve(file)
			} else {
				resolve()
			}
		})
	}

	const isLoading = isUploadingCsv || (taskId !== undefined && status === 'active')
	const isFinished = taskId !== undefined && status !== 'active'

	return (
		<FlatfileProvider
			publishableKey={process.env.NEXT_PUBLIC_FLAT_FILE_PUBLISHABLE_KEY}
			environmentId={process.env.NEXT_PUBLIC_FLAT_FILE_ENVIRONMENT_KEY}
		>
			<Modal
				title={title}
				visible={visible}
				onCancel={onCancel}
				maskClosable={false}
				okText={isUploadingCsv ? 'Validating' : isLoading ? 'Uploading...' : isFinished ? 'OK' : importOnly ? 'Import' : 'Upload'}
				okButtonProps={{
					loading: isLoading,
					disabled: !file
				}}
				closable={!isLoading}
				onOk={isFinished ? onOk : onUploadFile}
				cancelButtonProps={{
					disabled: isLoading
				}}
				width={720}
			>
				<div>
					<div className={styles.uploadContainer}>
						<div style={{ display: 'flex' }}>
							<div className={styles.item}>
								<div>
									<span style={{ marginRight: 12 }}><b>1.</b></span>
									<span>{firstStepPrompt}</span>
								</div>
								<Button
									onClick={downloadCsv}
									loading={isDownloadingCsv}
									style={{ marginTop: 24 }}
									icon={<DownloadOutlined />}
								>
								Download CSV
								</Button>
							</div>
							<div className={styles.item}>
								<div>
									<span style={{ marginRight: 12 }}><b>2.</b></span>
									<span>{secondStepPrompt}</span>
								</div>
							</div>
						</div>
						{
							fields.length > 0 &&
						<div className={styles.uploadItem}>
							<div>
								<div>
									<span style={{ marginRight: 12 }}><b>3.</b></span>
									<span>Start importing your data.</span>
								</div>
								{
									loading ? <div className={styles.import}>Initializing...</div> :
										<div className={styles.import}>
											<FlatFilePortal
												sheet={{
													name: type.name,
													slug: type.slug?.toLowerCase(),
													fields
												}}
												onSubmit={async ({ validData, fileName }) => {
													if (importOnly) {
														setImportedValues(validData)
														setFile({
															name: fileName
														})
													} else {
														try {
															const file = await parseResults({
																validData,
																fileName
															})
															setFile(file)
														} catch (error) {
															notification.error({
																message: 'Error parsing file',
																description: `${error.message}`,
																placement: 'bottomRight'
															})
														}
													}
												}}
											/>
											{
												file &&
										<div className={styles.file}>
											{file.name}
										</div>
											}
										</div>
								}
							</div>
						</div>
						}
					</div>
					{
						taskId &&
					<Progress percent={progress} format={percent => `${Math.round(percent)} %`} size='small' status={status} style={{ marginTop: 24 }} />
					}
					{
						logs && logs.length > 0 ?
							<div className={styles.output}>
								{
									logs.map((data, index) => {
										return (
											<div key={index} className={styles.outputRow}>
												{data}
											</div>
										)
									})
								}
							</div> : null
					}
					{
						logs && logs.length > 0 ?
							<Button
								style={{ marginLeft: 'auto', marginTop: 24 }}
								size='small'
								onClick={onDownloadErrorRows}
								loading={isDownloadingErrorCsv}
								disabled={!taskId}
								icon={<DownloadOutlined />}
							>
							Download Error Rows
							</Button> : null
					}
				</div>
			</Modal>
		</FlatfileProvider>
	)
}

export default UploadCsvModal
